.ibm-plex mono-regular {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-mono-bold {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 700;
  font-style: normal;
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.word {
  position: relative;
  z-index: 2;
  color: white;
}

.laser-beam {
  position: absolute;
  top: 50%;
  left: -100%;
  transform: translateY(-50%);
  width: 100px;
  height: 6px;
  background-color: yellow;
  animation: beam 1s linear forwards;
  animation-delay: 1.7s;
  box-shadow: 0 0 10px yellow;
  z-index: 1;
}

.laser-beam2 {
  position: absolute;
  top: 50%;
  left: -100%;
  transform: translateY(-50%);
  width: 100px;
  height: 6px;
  background-color: yellow;
  animation: beam 1s linear forwards;
  animation-delay: 1.9s;
  box-shadow: 0 0 10px yellow;
  z-index: 1;
}

@keyframes beam {
  0% {
    left: -100%;
  }
  100% {
    left: 200%;
  }
}

.blinking-cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}